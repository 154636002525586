import { getFormState } from './formstate';
import { hasItems } from './matchers';

export const FORM_NAME = 'vehicleInformation';

export const allState = () => ({
  vehiclesState: getFormState(FORM_NAME),
});

const createQueriesCore = vehiclesState => {
  const vehicles = Object.values(vehiclesState || {});
  const vehiclesAreSetup = () => hasItems(Object.keys(vehicles));

  return {
    get vehiclesAreSetup() {
      return vehiclesAreSetup();
    },

    get allVehicles() {
      return vehicles;
    },
  };
};

export const createQueriesHook = ({ vehiclesState } = allState()) =>
  createQueriesCore(vehiclesState.values);

export const createQueriesFromFormState = ({ [FORM_NAME]: vehiclesState }) =>
  createQueriesCore(vehiclesState);

export const getVehicles = () => {
  const vehiclesFormState = getFormState(FORM_NAME);
  const { values: vehicles } = vehiclesFormState;
  return vehicles;
};
