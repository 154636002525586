import './Checkbox.scss';
import { Form } from 'semantic-ui-react';
import React from 'react';

const Checkbox = ({ id, label, checked, onChange, onBlur }) => (
  <Form.Checkbox
    className="Checkbox"
    label={label}
    name={id}
    type="checkbox"
    checked={!!checked}
    id={id}
    value="true"
    onChange={onChange}
    onBlur={onBlur}
  />
);

export default Checkbox;
