import {
  equalTo as equals,
  isNotNullOrUndefined,
  isTrue,
  createAndMatch as where,
} from './criteria';
import { allState as allStepStatusState } from './stepStatus';
import { completionStep } from './stepDefinitions';
import { createQueriesHook as createAllQueries } from './allQueries';
import { getStepEventTriggers } from '../events';
import { navigate } from 'gatsby';
import { workflowSelector } from './wizardWorkflows';

let pathNameCache;
let menuCache;

const menuByPath = path =>
  where({
    path: equals(path),
  });

const stepFactorsIntoCompletionCount = where({
  includeInCompletionCount: isTrue,
});

export const getCurrentStepNum = locationProps => {
  if (!menuCache) return 0;

  let { pathname } = locationProps || {};

  if (!pathname) {
    pathname = pathNameCache;
  } else {
    pathNameCache = pathname;
  }

  return menuCache.findIndex(menuByPath(pathname));
};

const getNextAvailableStep = ({ currentStepNum, menu }) => {
  const isAvailable = where({
    isAvailable: true,
  });

  return menu.find((item, index) => isAvailable(item) && index > currentStepNum);
};

const nextStep = ({ stepStatusState }) => {
  const currentStepNum = getCurrentStepNum();
  const menu = menuCache;

  if (currentStepNum + 1 === menu.length) {
    return;
  }

  const currentStep = menu[currentStepNum];

  const stepTriggers = getStepEventTriggers({
    eventName: currentStep.eventName,
    stepStatusState,
  });

  stepTriggers.completed();

  const nextStepToRun =
    getNextAvailableStep({ currentStep, currentStepNum, menu }) || currentStep;

  navigate(nextStepToRun.path);
};

export const nextStepGenerator = () => {
  const { stepStatusState } = allStepStatusState();
  return () => nextStep({ stepStatusState });
};

export const navigateToStep = step => {
  navigate(step.path);
};

export const getMenu = () => {
  const allQueries = createAllQueries();

  const workflow = workflowSelector({ ...allQueries });

  menuCache = workflow.buildMenu(allQueries);

  return menuCache;
};

export const stepIsComplete = step =>
  step.isComplete.or(step.isSkipped)(createAllQueries());

export const roundedCompletionPercentage = value => Math.round(100 * value);

export const getStepCompletionPercentage = (menu = getMenu()) => {
  const applicableSteps = menu.filter(stepFactorsIntoCompletionCount);
  const allQueries = createAllQueries();
  const numSteps = applicableSteps.length;

  const noProgress = {
    completedPercentage: 0,
    skippedPercentage: 0,
    totalPercentage: 0,
  };

  // If there are no steps to do, then take them straight to done
  if (numSteps === 0) {
    return { completedPercentage: 100, skippedPercentage: 0, totalPercentage: 100 };
  }

  if (allQueries.wizardStepsNotStarted) return noProgress;

  const completedCount = applicableSteps.filter(where({ completed: true })).length;
  const skippedCount = applicableSteps.filter(where({ skipped: true })).length;
  const onlyStepIsCompletion = menu.length === 1 && menu[0].id === completionStep.id;

  const completedPercentage = roundedCompletionPercentage(
    onlyStepIsCompletion ? 1 : completedCount / numSteps
  );
  const skippedPercentage = roundedCompletionPercentage(
    onlyStepIsCompletion ? 1 : skippedCount / numSteps
  );
  const totalPercentage = completedPercentage + skippedPercentage;

  return {
    completedPercentage,
    skippedPercentage,
    totalPercentage,
  };
};

export const stepIsAvailableInCurrentWorkFlow = step =>
  isNotNullOrUndefined(
    getMenu().find(
      where({
        eventName: step.eventName,
        isAvailable: true,
      })
    )
  );
