import Axios from 'axios';
import { STATES_WITH_LICENSE_VALIDATION } from '../../functions/constants';
import { getFormState } from './formstate';
import { getUser } from './auth';
import { hasItems } from './matchers';
import { registerLicense as registerLicenseLegacy } from '../legacyIntegration/';

export const FORM_NAME = 'licenseInformation';
export const LICENSE_DETAILS_FORM_NAME = 'licenseDetails';
export const STATES_WITH_DEFERRED_LICENSE_REGISTRATION = ['wa'];

export const allState = () => ({
  licensesState: getFormState(FORM_NAME),
  licenseDetailsState: getFormState(LICENSE_DETAILS_FORM_NAME),
});

const createQueriesCore = ({ licensesState, licenseDetailsState }) => {
  const values = licensesState || {};
  const licenses = values.licenses || [];

  const licenseDetailsValues = licenseDetailsState || {};

  const licenseIsConfirmed = license => licenseDetailsValues[license];

  const onlyRegisteringForRetail =
    values.wantsToUseRetailProduct && !values.wantsToUseGrowFlowProduct;

  const allLicensesAreConfirmed =
    hasItems(licenses) && licenses.every(licenseIsConfirmed);

  return {
    allLicensesAreConfirmed,
    onlyRegisteringForRetail,
    ...values,
    licenses,
  };
};

export const createQueriesHook = ({ licensesState, licenseDetailsState } = allState()) =>
  createQueriesCore({
    licensesState: licensesState.values,
    licenseDetailsState: licenseDetailsState.values,
  });

export const createQueriesFromFormState = ({
  [FORM_NAME]: licensesState,
  [LICENSE_DETAILS_FORM_NAME]: licenseDetailsState,
}) => createQueriesCore({ licensesState, licenseDetailsState });

export const updateLicenseName = (licenseFormState, oldName, newName) => {
  const { setFormState, formState, formName } = licenseFormState;
  const licenses = (formState[formName] && formState[formName].licenses) || [];
  const newLicenses = licenses.filter(x => x !== oldName);
  newLicenses.push(newName);
  formState[formName] = { ...formState[formName], licenses: newLicenses };
  setFormState(formState);
};

export const getLicenses = () => {
  const licenseFormState = getFormState(FORM_NAME);
  const { licenses } = licenseFormState.values;
  return { licenses, licenseFormState };
};

export const getLicenseDetails = async ({ state, licenses }) => {
  try {
    const response = await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
      operationName: null,
      variables: {
        state,
        licenses,
      },
      query: `
      query($state: String!, $licenses: [String]!) {
        getVendorLicenses(state: $state, licenses: $licenses)
      }
    `,
    });

    return JSON.parse(response.data.data.getVendorLicenses);
  } catch (err) {
    return [];
  }
};

export const isValidLicense = async ({ state, license }) => {
  if (!STATES_WITH_LICENSE_VALIDATION.includes(state.toLowerCase())) return true;

  const result = await getLicenseDetails({ state, licenses: [license] });

  return result.length > 0;
};

export const registerLicense = async ({
  leafDataApiKey,
  state,
  password,
  license,
  email,
}) => {
  const user = getUser();

  if (!STATES_WITH_DEFERRED_LICENSE_REGISTRATION.includes(state.toLowerCase()))
    return { success: true, errors: [] };

  return await registerLicenseLegacy({
    state,
    license,
    password,
    leafDataApiKey,
    email,
    idToken: user.idToken,
  });
};
