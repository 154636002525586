import { alwaysMatches, neverMatches } from './criteria';
import uuid4 from 'uuid4';

const noHelp = {
  link: '/nowhere_for_now',
  text: null,
};

const emptyIntro = {
  header: '',
  bodyText: '',
  bodyText2: '',
  requirements: [],
  buttonText: '',
  needHelp: noHelp,
  videoID: '',
};

export const createWizardStep = ({
  path,
  prettyName,
  eventName,
  includeInCompletionCount = true,
  isComplete = neverMatches,
  isSkipped = neverMatches,
  isUIStep = true,
  intro = emptyIntro,
  arePrerequisitesMet = alwaysMatches,
}) => ({
  id: uuid4(),
  isUIStep,
  path,
  prettyName,
  eventName,
  includeInCompletionCount,
  arePrerequisitesMet,
  isComplete,
  isSkipped,
  intro,
});

export const createIntro = ({
  header = '',
  bodyText = '',
  bodyText2 = '',
  requirements = [],
  buttonText = '',
  needHelp = noHelp,
  videoID = '',
} = emptyIntro) => ({
  header,
  bodyText,
  bodyText2,
  requirements,
  buttonText,
  needHelp,
  videoID,
});
