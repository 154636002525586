import { not, createAndMatch as where } from './criteria';

import { stateLookup } from '../data/StateOptions';

export const isWashingtonCriteria = where({
  state: stateLookup('WA').value,
});

export const onlyRegisteringForRetail = where({
  onlyRegisteringForRetail: true,
});

export const growFlowCalendlyBookingAvailable = () => false;

export const growFlowCalendlyBookingAvailableForUnconfiguredState = () => false;

export const isRegularWorkflow = not(isWashingtonCriteria.or(onlyRegisteringForRetail));
