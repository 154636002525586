import {
  differenceInYears,
  format,
  isFuture,
  parse,
  startOfDay,
  subYears,
} from 'date-fns';

const defaultParseFormat = 'MM/dd/yyyy';

export const defaultDateInputFormat = 'MM/DD/YYYY';

export { differenceInYears, startOfDay, isFuture };

export const parseDate = val => parse(val, defaultParseFormat, new Date());

export const formatDate = (val, dateFormat = defaultParseFormat) =>
  format(val, dateFormat);

export const subtractYearsFromDate = subYears;

export const subtractYearsFromToday = years =>
  subtractYearsFromDate(startOfDay(new Date()), years);

export const today = () => new Date();
