import { completionStep, resumeStep } from './stepDefinitions';

import { equalTo, not, createOrMatch as where } from './criteria';

const stepIsComplete = where({
  completed: true,
  skipped: true,
});

const isCompletionStep = equalTo(completionStep);
const isResumeStep = equalTo(resumeStep);
const isRegularStep = not(isCompletionStep.or(isResumeStep));

const createWorkflowMenuBuilder = steps => {
  const regularSteps = steps.filter(isRegularStep);
  const lastStep = steps[steps.length - 1];

  return data => {
    const items = regularSteps.reduce((acc, step) => {
      const prereqsAreMet = step.arePrerequisitesMet(data);

      const resultStep = {
        ...step,
        completed: step.isComplete(data),
        skipped: !data.wizardStepsNotStarted && (!prereqsAreMet || step.isSkipped(data)),
        isAvailable: prereqsAreMet,
      };

      return acc.concat(resultStep);
    }, []);

    const allStepsCompleted = items.every(stepIsComplete);

    return items.concat({
      ...lastStep,
      isAvailable: allStepsCompleted,
      completed: allStepsCompleted,
    });
  };
};

export const createWorkflow = ({ title, steps, availabilityCriteria }) => ({
  title,
  availabilityCriteria,
  buildMenu: createWorkflowMenuBuilder(steps),
});

export const createWorkflowSelector = workflows => criteriaData =>
  workflows.find(workflow => workflow.availabilityCriteria(criteriaData));
