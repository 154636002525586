import { condition, isNotNullOrUndefined } from './criteria';
import { curry, noOp } from './funcy';
import { deleteFormElement, getFormState, handleFormSubmit } from './formstate';

import React from 'react';
import SkippableSection from '../components/widgets/SkippableSection';
import { createSkipKeyBuilder } from './skipKeyBuilder';

export const SKIP_FORM_NAME = 'skipEntries';

export const employeesSkipKeyBuilder = createSkipKeyBuilder('employees');

export const vehiclesSkipKeyBuilder = createSkipKeyBuilder('vehicles');

export const strainsSkipKeyBuilder = createSkipKeyBuilder('strains');

export const skipPlantRoomsKeyBuilder = createSkipKeyBuilder(`plant-rooms-for-license`);

export const skipInventoryRoomsKeyBuilder = createSkipKeyBuilder(
  `inventory-rooms-for-license`
);

export const skipPlantGroupKeyBuilder = createSkipKeyBuilder(`plant-group-for-room`);

export const skipSeedGroupKeyBuilder = createSkipKeyBuilder(`seed-group-for-license`);

export const skipCloneGroupKeyBuilder = createSkipKeyBuilder(
  `clone-group-for-licenseroom`
);

export const skipBulkFlowerGroupKeyBuilder = createSkipKeyBuilder(
  `bulk-flower-group-for-license`
);

export const skipShakeTrimGroupKeyBuilder = createSkipKeyBuilder(
  `shake-trim-group-for-license`
);

export const allState = () => ({
  skipState: getFormState(SKIP_FORM_NAME),
});

const createKeyChecker = curry((entries, key) => isNotNullOrUndefined(entries[key]));

const createQueriesCore = skipState => {
  const entries = skipState || {};
  const keyExists = createKeyChecker(entries);

  const isSkippingItem = keyExists;

  const skippingEmployees = keyExists(employeesSkipKeyBuilder());

  const skippingVehicles = keyExists(vehiclesSkipKeyBuilder());

  const skippingStrains = keyExists(strainsSkipKeyBuilder());

  const skippingPlantRoomsForLicense = condition(license =>
    keyExists(skipPlantRoomsKeyBuilder(license))
  );

  const skippingInventoryRoomsForLicense = condition(license =>
    keyExists(skipInventoryRoomsKeyBuilder(license))
  );

  const skippedSetupOfAllRoomTypesForLicense = skippingPlantRoomsForLicense.and(
    skippingInventoryRoomsForLicense
  );

  const skippingPlantsForRoom = roomId => keyExists(skipPlantGroupKeyBuilder(roomId));

  const skippingSeedGroupsForLicense = condition(license =>
    keyExists(skipSeedGroupKeyBuilder(license))
  );

  const skippingCloneGroupsForLicense = condition(license =>
    keyExists(skipCloneGroupKeyBuilder(license))
  );

  const skippingBulkFlowerGroupsForLicense = condition(license =>
    keyExists(skipBulkFlowerGroupKeyBuilder(license))
  );

  const skippingShakeTrimGroupsForLicense = condition(license =>
    keyExists(skipShakeTrimGroupKeyBuilder(license))
  );

  const skippingAllInventoryForLicense = skippingSeedGroupsForLicense
    .and(skippingCloneGroupsForLicense)
    .and(skippingBulkFlowerGroupsForLicense)
    .and(skippingShakeTrimGroupsForLicense);

  return {
    isSkippingItem,

    skippingEmployees,

    skippingVehicles,

    skippingStrains,

    skippingPlantRoomsForLicense,

    skippingInventoryRoomsForLicense,

    skippedSetupOfAllRoomTypesForLicense,

    skippingPlantsForRoom,

    skippingSeedGroupsForLicense,

    skippingCloneGroupsForLicense,

    skippingBulkFlowerGroupsForLicense,

    skippingShakeTrimGroupsForLicense,

    skippingAllInventoryForLicense,
  };
};

export const createQueriesHook = ({ skipState } = allState()) =>
  createQueriesCore(skipState.values);

export const createQueriesFromFormState = ({ [SKIP_FORM_NAME]: skipState }) =>
  createQueriesCore(skipState);

const saveSkipEntry = (skipState, entry) => {
  const submit = handleFormSubmit(skipState);
  submit(entry);
};

const createSaveSkipEntry = ({ skipState, completion = noOp }) => entry => {
  saveSkipEntry(skipState, entry);
  completion();
};

const createSkipChangeHandlerDependencies = {
  createSaveHandler: skipState => createSaveSkipEntry({ skipState }),

  /**
   *[HACK - Trying to assign directly to deleteFormElement results in undefined, so I have to wrap the function call here (JP - 2019-10-20)]*
   *
   */
  deleteElement: (skipState, id) => deleteFormElement(skipState, id),
  createQueries: createQueriesHook,
};

export const createSkipChangeHandler = ({ skipState, keyBuilder, key }) => {
  const {
    createSaveHandler,
    deleteElement,
    createQueries,
  } = createSkipChangeHandlerDependencies;

  const saveHandler = createSaveHandler(skipState);

  const id = keyBuilder(key);

  return () => {
    const queries = createQueries({ skipState });

    const isCurrentlySkipped = queries.isSkippingItem(id);

    if (isCurrentlySkipped) {
      deleteElement(skipState, id);
      return;
    }

    saveHandler({ id });
  };
};

const createSkippableSectionBuilderForKeyDependencies = {
  createQueries: createQueriesHook,
  createSkipChangeHandler,
  buildSkippableSection: ({ label, skipped, canSkip, handler, children }) => (
    <SkippableSection
      label={label}
      skipped={skipped}
      canSkip={canSkip}
      onSkipChanged={handler}
    >
      {children}
    </SkippableSection>
  ),
};

export const createSkippableSectionBuilderForKey = (
  { label, canSkip, skipState, keyBuilder },
  key = ''
) => {
  const deps = createSkippableSectionBuilderForKeyDependencies;
  const handler = deps.createSkipChangeHandler({ skipState, keyBuilder, key });
  const queries = deps.createQueries({ skipState });
  const skipped = queries.isSkippingItem(keyBuilder(key));

  return children =>
    deps.buildSkippableSection({
      label,
      skipped,
      canSkip,
      handler,
      children,
    });
};

export const __test__ = {
  createQueriesCore,
  createKeyChecker,
  createSkipChangeHandlerDependencies,
  createSkippableSectionBuilderForKeyDependencies,
};
