import { isEmpty, not, createAndMatch as where } from './criteria';
import { getFormState } from './formstate';
import { hasItems } from './matchers';

export const FORM_NAME = 'inventory';

const nonEmpty = not(isEmpty);

export const allState = () => ({
  inventoryState: getFormState(FORM_NAME),
});

const allKeysAreNonEmpty = map =>
  Object.entries(map)
    .map(([, val]) => val)
    .every(nonEmpty);

const allQuestionsAreAnswered = where({
  pp: allKeysAreNonEmpty,
  rp: allKeysAreNonEmpty,
});

const createQueriesCore = inventoryState => {
  const details = inventoryState || {};

  const items = Object.entries(details)
    .map(([, val]) => val)
    .slice(-1);

  const allInventoryQuestionsHaveBeenAnswered =
    hasItems(items) && items.every(allQuestionsAreAnswered);

  return {
    allInventoryQuestionsHaveBeenAnswered,
  };
};

export const createQueriesHook = ({ inventoryState }) =>
  createQueriesCore(inventoryState.values);

export const createQueriesFromFormState = ({ [FORM_NAME]: inventoryState }) =>
  createQueriesCore(inventoryState);
