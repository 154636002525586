import { alwaysMatches, neverMatches, createAndMatch as where } from './criteria';
import { createIntro, createWizardStep } from './wizardSteps';

const strainsHaveBeenConfigured = where({
  strainsHaveBeenSetup: true,
});

const allLicensesHaveBeenConfirmed = where({
  allLicensesAreConfirmed: true,
});

export const nulloStep = {
  ...createWizardStep({
    prettyName: 'Nullo Step',

    isUIStep: false,

    includeInCompletionCount: false,

    isComplete: alwaysMatches,

    isSkipped: neverMatches,

    arePrerequisitesMet: alwaysMatches,
  }),

  isAvailable: true,

  completed: true,
};

export const resumeStep = {
  ...createWizardStep({
    path: '/Wizard/Resume/',

    prettyName: 'Loading...',

    eventName: 'IgnoredEvent',

    includeInCompletionCount: false,

    arePrerequisitesMet: alwaysMatches,
  }),

  isAvailable: true,

  completed: true,
};

export const completionStep = createWizardStep({
  path: '/Wizard/Complete/',

  prettyName: 'Finish & Setup Intro Call',

  includeInCompletionCount: false,
});

export const companyInformationStep = createWizardStep({
  path: '/Wizard/CompanyInformation/',

  prettyName: 'Confirm License Information',

  eventName: 'CompanyInformation',

  isComplete: allLicensesHaveBeenConfirmed,

  intro: createIntro({
    header: 'Confirm License Information',

    bodyText:
      'To make sure your company is compliant with state regulations, we need the actual physical address of the operation.',

    requirements: [
      'List of all your Licenses',
      'Actual physical address for each License',
      "Any 'Doing-Business-As' names for Licenses",
      'API Keys for each license (if applicable)',
    ],

    buttonText: '+ Confirm License',

    videoID: 'y5hd4fifhp',
  }),
});

export const deliveryVehiclesStep = createWizardStep({
  path: '/Wizard/DeliveryVehicles/',

  prettyName: 'Delivery Vehicles',

  eventName: 'DeliveryVehicles',

  isComplete: where({
    vehiclesAreSetup: true,
  }),

  isSkipped: where({
    skippingVehicles: true,
  }),

  arePrerequisitesMet: allLicensesHaveBeenConfirmed,

  intro: createIntro({
    header: 'Add Vehicle Details',

    bodyText:
      'In order to sell product and make deliveries GrowFlow generates a manifest for each of your orders. To create the manifest, we legally need to provide the correct vehicle information on each manifest.',

    requirements: [
      'List of all your delivery vehicles',
      'License Plate Numbers for all delivery vehicles',
      'VIN Numbers for all delivery vehicles',
      'Make, model, color & year of delivery vehicles',
    ],

    buttonText: '+ Add Vehicle Details',

    videoID: '0hwo4pabx2',
  }),
});

export const employeesStep = createWizardStep({
  path: '/Wizard/Employees/',

  prettyName: 'Employees',

  eventName: 'Employees',

  isComplete: where({
    hasEmployees: true,
  }),

  isSkipped: where({
    skippingEmployees: true,
  }),

  arePrerequisitesMet: allLicensesHaveBeenConfirmed,

  intro: createIntro({
    header: 'Add Owner/Employee Details',

    bodyText:
      'For deliveries we will also need information about the employee driving the delivery vehicle. You can also assign tasks to owners/employees in GrowFlow and invite owners/employees as users to your account during this process, while also setting their permissions.',

    requirements: [
      'List of all of your Owners/Employees',
      'Their Company Email Addresses',
      'Their Phone numbers',
      "Their Date-of-Birth's and hire dates",
    ],

    buttonText: '+ Add Owner/Employee Details',

    videoID: '9u4jndw2mo',
  }),
});

export const strainsStep = createWizardStep({
  path: '/Wizard/Strains/',

  prettyName: 'Strains',

  eventName: 'Strains',

  isComplete: strainsHaveBeenConfigured,

  arePrerequisitesMet: allLicensesHaveBeenConfirmed,

  isSkipped: where({
    skippingStrains: true,
  }),

  intro: createIntro({
    header: 'Add Strain Details',

    bodyText:
      'In the upcoming steps, we will use the complete list of strains you enter at this point, in order to get all of your plants and inventory into GrowFlow.',

    bodyText2:
      'Make sure to include ALL STRAINS you are either growing or have in inventory.',

    requirements: ['List of all of your Strains - both plants and inventory'],

    buttonText: '+ Add Strain Details',

    videoID: 'umprvp8ch1',
  }),
});

export const roomsStep = createWizardStep({
  path: '/Wizard/Rooms/',

  prettyName: 'Rooms',

  eventName: 'Rooms',

  isComplete: where({
    roomsHaveBeenSetupForAllLicenses: true,
    roomsHaveBeenSkippedForAllLicenses: false,
  }),

  isSkipped: where({
    roomsHaveBeenSkippedForAllLicenses: true,
  }),

  arePrerequisitesMet: allLicensesHaveBeenConfirmed,

  intro: createIntro({
    header: 'Add Room Details',

    bodyText:
      'To track and catalog all of the plants and inventory at your operation, we need to understand all of the rooms at your operation.',

    requirements: [
      'List of all of your Rooms',
      'Categorizing your Plant Rooms',
      'Categorizing your Inventory Rooms',
      'Names for each room (clever & fun?)',
    ],

    buttonText: '+Add Room Details',

    videoID: 'ea17njqtgk',
  }),
});

export const plantsStep = createWizardStep({
  path: '/Wizard/Plants/',

  prettyName: 'Plants',

  eventName: 'Plants',

  isComplete: where({
    plantsHaveBeenAdded: true,
  }),

  isSkipped: where({
    plantGroupsHaveBeenSkippedForAllRooms: true,
  }),

  arePrerequisitesMet: strainsHaveBeenConfigured.and(
    where({
      plantRoomsHaveBeenSetupForAtLeastOneLicense: true,
    })
  ),

  intro: createIntro({
    header: 'Add Plant Data',

    bodyText:
      'In order to build out your account, we need to get all of your plants into GrowFlow. This section will make that as easy as possible.',

    requirements: [
      'List of all of your Plants, by room',
      'Within each room, group Plants by Strain',
      'Within each Strain Group, plants by DOB',
      'Harvested plants wet and dry weights',
    ],

    buttonText: '+ Add Plant Data',

    videoID: 'mftvr83kdg',
  }),
});

export const plantInventoryStep = createWizardStep({
  path: '/Wizard/PlantInventory/',

  prettyName: 'Plant Inventory',

  eventName: 'PlantInventory',

  isComplete: where({
    plantInventoryHasBeenSetup: true,
  }),

  isSkipped: where({
    inventoryHasBeenSkippedForAllLicensesWithRooms: true,
  }),

  arePrerequisitesMet: strainsHaveBeenConfigured.and(
    where({
      roomsHaveBeenSetupForAtLeastOneLicense: true,
    })
  ),

  intro: createIntro({
    header: 'Add Plant Inventory Data',

    bodyText:
      'In order to build out your account, we need to get all of your plant inventory into GrowFlow. This section will make that as easy as possible.',

    requirements: [
      'List of all your Clones & Seeds, by room',
      'Within each room, group by Strain',
      'Within each Strain Group, by DOB',
      'Bulk Flower and Shake/Trim groups',
    ],

    buttonText: '+ Add Plant Inventory Data',

    videoID: '267l44tiul',
  }),
});

export const inventoryStep = createWizardStep({
  path: '/Wizard/Inventory/',

  prettyName: 'Inventory',

  eventName: 'Inventory',

  isComplete: where({
    allInventoryQuestionsHaveBeenAnswered: true,
  }),

  arePrerequisitesMet: strainsHaveBeenConfigured.and(
    where({
      plantInventoryHasBeenSetup: true,
    })
  ),

  intro: createIntro({
    header: 'Add Inventory Data',

    bodyText:
      'So close! We just need to understand what inventory you have on hand, and where that inventory is at your operation. This step will make getting all of your inventory into GrowFlow as easy as possible.',

    requirements: [
      'List of all of your Inventory by room',
      'Within each room, inventory by product type',
      'Within each product type, inventory by strain',
      'Weights for all inventory items',
      'Creation dates for all inventory items',
    ],

    buttonText: '+ Add Inventory Data',

    videoID: '1ei0s2rlbq',
  }),
});

export const printersAndLabelsStep = createWizardStep({
  path: '/Wizard/PrintersAndLabels/',

  prettyName: 'Printers & Labels',

  eventName: 'PrintersAndLabels',

  isComplete: where({
    allPrintersHaveBeenSetup: true,
  }),

  arePrerequisitesMet: allLicensesHaveBeenConfirmed,

  intro: createIntro({
    header: 'Add Printer & Label Details',

    bodyText:
      'To make sure your company is compliant with state regulations, we need to have your company’s accurate addresses.',

    requirements: [
      'List of all of printer model numbers',
      'List of label manufacture & product numbers',
      'List of label sizes for each printer',
    ],

    buttonText: '+ Add Printer & Label Details',

    videoID: '8ev9xhe1a8',
  }),
});
