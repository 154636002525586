export const createDropDownOptions = values =>
  values.reduce(
    (acc, val, index) => ({
      [index + 1]: {
        id: index + 1,
        value: val,
        text: val,
      },
      ...acc,
    }),
    {}
  );

export const previousTraceabilityProviderOptions = createDropDownOptions([
  'No System - Hand Reporting',
  'BioTrack',
  'MJ Freeway',
  'Metrc',
  'Other',
]);

export const leadSourceOptions = createDropDownOptions([
  'Google Search',
  'Facebook Group',
  'Referred by a friend',
  'Growflow Sales Team',
  'Other',
]);

export const plantRoomPurposeOptions = createDropDownOptions([
  'Vegging Plants',
  'Flowering Plants',
  'Drying Plants/Material',
  'Multi-purpose',
  'Other',
]);
