import { isEmpty as empty, createAndMatch as where } from './criteria';
import { getFormState, handleFormSubmit } from './formstate';
import { curry } from './funcy';
import { hasItems } from './matchers';

export const SEED_GROUPS_FORM_NAME = 'seedGroupsInformation';
export const CLONE_GROUPS_FORM_NAME = 'cloneGroupsInformation';
export const BULK_FLOWER_GROUPS_FORM_NAME = 'bulkFlowerGroupsInformation';
export const SHAKE_TRIM_GROUPS_FORM_NAME = 'shakeTrimGroupsInformation';

const noOp = () => {};

export const allState = () => ({
  seedGroupsState: getFormState(SEED_GROUPS_FORM_NAME),
  cloneGroupsState: getFormState(CLONE_GROUPS_FORM_NAME),
  bulkFlowerGroupsState: getFormState(BULK_FLOWER_GROUPS_FORM_NAME),
  shakeTrimGroupsState: getFormState(SHAKE_TRIM_GROUPS_FORM_NAME),
});

const createQueriesCore = ({
  seedGroupsState,
  cloneGroupsState,
  bulkFlowerGroupsState,
  shakeTrimGroupsState,
}) => {
  const seedGroups = () => Object.values(seedGroupsState || {});
  const cloneGroups = () => Object.values(cloneGroupsState || {});
  const bulkFlowerGroups = () => Object.values(bulkFlowerGroupsState || {});
  const shakeTrimGroups = () => Object.values(shakeTrimGroupsState || {});

  const allGroups = () =>
    [].concat(seedGroups(), cloneGroups(), bulkFlowerGroups(), shakeTrimGroups());

  const itemsForLicense = curry((resolver, license) =>
    resolver().filter(where({ license }))
  );

  const hasInventoryTypeForLicense = curry((itemsResolver, license) =>
    hasItems(itemsResolver(license))
  );

  const seedGroupsForLicense = itemsForLicense(seedGroups);
  const cloneGroupsForLicense = itemsForLicense(cloneGroups);
  const bulkFlowerGroupsForLicense = itemsForLicense(bulkFlowerGroups);
  const shakeTrimGroupsForLicense = itemsForLicense(shakeTrimGroups);

  const hasSeedGroupsForLicense = hasInventoryTypeForLicense(seedGroupsForLicense);
  const hasCloneGroupsForLicense = hasInventoryTypeForLicense(cloneGroupsForLicense);
  const hasBulkFlowerGroupsForLicense = hasInventoryTypeForLicense(
    bulkFlowerGroupsForLicense
  );
  const hasShakeTrimGroupsForLicense = hasInventoryTypeForLicense(
    shakeTrimGroupsForLicense
  );

  const hasAnyInventoryForLicense = license =>
    hasSeedGroupsForLicense(license) ||
    hasCloneGroupsForLicense(license) ||
    hasBulkFlowerGroupsForLicense(license) ||
    hasShakeTrimGroupsForLicense(license);

  const groupDetails = () =>
    allGroups().reduce(
      (acc, val) => ({
        [val.id]: val,
        ...acc,
      }),
      {}
    );

  return {
    seedGroupsForLicense,
    hasSeedGroupsForLicense,

    cloneGroupsForLicense,
    hasCloneGroupsForLicense,

    bulkFlowerGroupsForLicense,
    hasBulkFlowerGroupsForLicense,

    shakeTrimGroupsForLicense,
    hasShakeTrimGroupsForLicense,

    hasAnyInventoryForLicense,

    get allPlantGroups() {
      return allGroups();
    },

    get groupDetails() {
      return groupDetails();
    },
  };
};

export const createQueriesHook = (
  {
    seedGroupsState,
    cloneGroupsState,
    bulkFlowerGroupsState,
    shakeTrimGroupsState,
  } = allState()
) =>
  createQueriesCore({
    seedGroupsState: seedGroupsState.values,
    cloneGroupsState: cloneGroupsState.values,
    bulkFlowerGroupsState: bulkFlowerGroupsState.values,
    shakeTrimGroupsState: shakeTrimGroupsState.values,
  });

export const createQueriesFromFormState = ({
  [SEED_GROUPS_FORM_NAME]: seedGroupsState,
  [CLONE_GROUPS_FORM_NAME]: cloneGroupsState,
  [BULK_FLOWER_GROUPS_FORM_NAME]: bulkFlowerGroupsState,
  [SHAKE_TRIM_GROUPS_FORM_NAME]: shakeTrimGroupsState,
}) =>
  createQueriesCore({
    seedGroupsState,
    cloneGroupsState,
    bulkFlowerGroupsState,
    shakeTrimGroupsState,
  });

const mainFields = ['dateOfBirth', 'license', 'roomId', 'strainId'];

const createValidator = extraFields => item =>
  mainFields
    .concat(extraFields)
    .reduce((acc, key) => (!empty(item[key]) ? acc : { [key]: 'Required', ...acc }), {});

export const validateSeedGroup = createValidator(['seedCount']);

export const newSeedGroup = license => ({
  dateOfBirth: '',
  license,
  roomId: '',
  seedCount: '',
  strainId: '',
});

export const validateCloneGroup = createValidator(['cloneCount']);

export const newCloneGroup = license => ({
  cloneCount: '',
  dateOfBirth: '',
  license,
  roomId: '',
  strainId: '',
});

export const validateBulkFlowerGroup = createValidator(['weightInGrams']);

export const newBulkFlowerGroup = license => ({
  dateOfBirth: '',
  license,
  roomId: '',
  strainId: '',
  weightInGrams: '',
});

export const validateShakeTrimGroup = createValidator(['weightInGrams']);

export const newShakeTrimGroup = license => ({
  dateOfBirth: '',
  license,
  roomId: '',
  strainId: '',
  weightInGrams: '',
});

const saveItem = (state, item) => {
  const submit = handleFormSubmit(state);

  submit(item);
};

const createSaveHandler = key => ({ [key]: state, completion = noOp }) => item => {
  saveItem(state, item);
  completion();
};

export const createSaveSeedGroupHandler = createSaveHandler('seedGroupsState');
export const createSaveCloneGroupHandler = createSaveHandler('cloneGroupsState');
export const createSaveBulkFlowerGroupHandler = createSaveHandler(
  'bulkFlowerGroupsState'
);
export const createSaveShakeTrimGroupHandler = createSaveHandler('shakeTrimGroupsState');
