import {
  isEmpty as empty,
  isNotNullOrUndefined,
  createAndMatch as where,
} from './criteria';
import { getFormState, handleFormSubmit } from './formstate';
import { trackPrinterSaved, trackPrinterUpdated } from '../events';
import newId from 'uuid4';

export const FORM_NAME = 'printersAndLabelsInformation';

export const PRINTER_TYPES = ['plant', 'inventory', 'retail'].reduce(
  (acc, val) => ({
    ...acc,
    [val]: val,
  }),
  {}
);

const printerByType = type => where({ type });

export const newPrinter = type => ({
  id: newId(),
  type,
  manufacturer: '',
  modelNumber: '',
  labelHeight: '',
  labelWidth: '',
  noPrinterSelected: false,
  labelStyleRollOfLabels: false,
  labelStyleSheetOfLabels: false,
  labelStyleNeither: true,
});

const defaultInventoryTagPrinter = newPrinter(PRINTER_TYPES.inventory);
const defaultPlantTagPrinter = newPrinter(PRINTER_TYPES.plant);
const defaultRetailLabelPrinter = newPrinter(PRINTER_TYPES.retail);

export const allState = () => ({
  printersAndLabelsState: getFormState(FORM_NAME),
});

const createQueriesCore = (printersAndLabelsState = {}) => {
  const printers = () => Object.values(printersAndLabelsState);
  const allPrintersHaveBeenSetup = printers().length === 3;

  return {
    plantTagPrinter:
      printers().find(printerByType(PRINTER_TYPES.plant)) || defaultPlantTagPrinter,
    inventoryTagPrinter:
      printers().find(printerByType(PRINTER_TYPES.inventory)) ||
      defaultInventoryTagPrinter,
    retailLabelPrinter:
      printers().find(printerByType(PRINTER_TYPES.retail)) || defaultRetailLabelPrinter,
    printerDetails: printersAndLabelsState,
    printers: printers(),
    allPrintersHaveBeenSetup,
    printerHasBeenSaved: ({ id }) => isNotNullOrUndefined(printersAndLabelsState[id]),
  };
};

export const createQueriesHook = ({ printersAndLabelsState } = allState()) =>
  createQueriesCore(printersAndLabelsState.values);

export const createQueriesFromFormState = ({ [FORM_NAME]: printersAndLabelsState }) =>
  createQueriesCore(printersAndLabelsState);

export const validatePrinter = form =>
  form.noPrinterSelected
    ? {}
    : ['manufacturer', 'modelNumber', 'labelHeight', 'labelWidth'].reduce(
        (acc, key) => (empty(form[key]) ? { [key]: 'Required', ...acc } : acc),
        {}
      );

const noOp = () => {};

const savePrinter = (printersAndLabelsState, printer) => {
  const submit = handleFormSubmit(printersAndLabelsState);
  const queries = createQueriesCore(printersAndLabelsState.values);

  // Trigger our Avo event calls for this printer
  if (queries.printerHasBeenSaved(printer)) trackPrinterUpdated(printer);
  else trackPrinterSaved(printer);

  submit(printer);
};

export const createSavePrinterHandler = ({
  printersAndLabelsState,
  completion = noOp,
}) => printer => {
  savePrinter(printersAndLabelsState, printer);
  completion();
};

export const __test__ = {
  createQueriesCore,
};
