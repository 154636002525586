export const createStateDetail = ({
  id,
  value,
  text,
  calendlyAvailable = false,
  collectsLeafAPIKey = false,
}) => ({
  id,
  value,
  text,
  collectsLeafAPIKey,
  calendlyAvailable,
  calendlyUrl: calendlyAvailable
    ? `https://calendly.com/growflow-customer-activation/${text.toLowerCase()}`
    : '',
});

// all options need an id
export const StateOptions = [
  { value: 'AL', text: 'ALABAMA', id: 'AL' },
  { value: 'AK', text: 'ALASKA', id: 'AK' },
  { value: 'AS', text: 'AMERICAN SAMOA', id: 'AS' },
  { value: 'AZ', text: 'ARIZONA', id: 'AZ' },
  { value: 'AR', text: 'ARKANSAS', id: 'AR' },
  {
    value: 'CA',
    text: 'CALIFORNIA',
    id: 'CA',
    calendlyAvailable: true,
    collectsLeafAPIKey: true,
  },
  { value: 'CO', text: 'COLORADO', id: 'CO' },
  { value: 'CT', text: 'CONNECTICUT', id: 'CT' },
  { value: 'DE', text: 'DELAWARE', id: 'DE' },
  { value: 'DC', text: 'DISTRICT OF COLUMBIA', id: 'DC' },
  { value: 'FM', text: 'FEDERATED STATES OF MICRONESIA', id: 'FM' },
  { value: 'FL', text: 'FLORIDA', id: 'FL' },
  { value: 'GA', text: 'GEORGIA', id: 'GA' },
  { value: 'GU', text: 'GUAM GU', id: 'GU' },
  { value: 'HI', text: 'HAWAII', id: 'HI' },
  { value: 'ID', text: 'IDAHO', id: 'ID' },
  { value: 'IL', text: 'ILLINOIS', id: 'IL' },
  { value: 'IN', text: 'INDIANA', id: 'IN' },
  { value: 'IA', text: 'IOWA', id: 'IA' },
  { value: 'KS', text: 'KANSAS', id: 'KS' },
  { value: 'KY', text: 'KENTUCKY', id: 'KY' },
  { value: 'LA', text: 'LOUISIANA', id: 'LA' },
  { value: 'ME', text: 'MAINE', id: 'ME' },
  { value: 'MH', text: 'MARSHALL ISLANDS', id: 'MH' },
  { value: 'MD', text: 'MARYLAND', id: 'MD' },
  { value: 'MA', text: 'MASSACHUSETTS', id: 'MA' },
  { value: 'MI', text: 'MICHIGAN', id: 'MI' },
  { value: 'MN', text: 'MINNESOTA', id: 'MN' },
  { value: 'MS', text: 'MISSISSIPPI', id: 'MS' },
  { value: 'MO', text: 'MISSOURI', id: 'MO' },
  { value: 'MT', text: 'MONTANA', id: 'MT' },
  { value: 'NE', text: 'NEBRASKA', id: 'NE' },
  { value: 'NV', text: 'NEVADA', id: 'NV', collectsLeafAPIKey: true },
  { value: 'NH', text: 'NEW HAMPSHIRE', id: 'NH' },
  { value: 'NJ', text: 'NEW JERSEY', id: 'NJ' },
  { value: 'NM', text: 'NEW MEXICO', id: 'NM' },
  { value: 'NY', text: 'NEW YORK', id: 'NY' },
  { value: 'NC', text: 'NORTH CAROLINA', id: 'NC' },
  { value: 'ND', text: 'NORTH DAKOTA', id: 'ND' },
  { value: 'MP', text: 'NORTHERN MARIANA ISLANDS', id: 'MP' },
  { value: 'OH', text: 'OHIO', id: 'OH' },
  { value: 'OK', text: 'OKLAHOMA', id: 'OK', calendlyAvailable: true },
  { value: 'OR', text: 'OREGON', id: 'OR' },
  { value: 'PW', text: 'PALAU', id: 'PW' },
  { value: 'PA', text: 'PENNSYLVANIA', id: 'PA' },
  { value: 'PR', text: 'PUERTO RICO', id: 'PR' },
  { value: 'RI', text: 'RHODE ISLAND', id: 'RI' },
  { value: 'SC', text: 'SOUTH CAROLINA', id: 'SC' },
  { value: 'SD', text: 'SOUTH DAKOTA', id: 'SD' },
  { value: 'TN', text: 'TENNESSEE', id: 'TN' },
  { value: 'TX', text: 'TEXAS', id: 'TX' },
  { value: 'UT', text: 'UTAH', id: 'UT' },
  { value: 'VT', text: 'VERMONT', id: 'VT' },
  { value: 'VI', text: 'VIRGIN ISLANDS', id: 'VI' },
  { value: 'VA', text: 'VIRGINIA', id: 'VA' },
  { value: 'WA', text: 'WASHINGTON', id: 'WA', calendlyAvailable: true },
  { value: 'WV', text: 'WEST VIRGINIA', id: 'WV' },
  { value: 'WI', text: 'WISCONSIN', id: 'WI' },
  { value: 'WY', text: 'WYOMING', id: 'WY' },
  { value: 'AE', text: 'ARMED FORCES AFRICA  CANADA  EUROPE  MIDDLE EAST', id: 'AE' },
  { value: 'AA', text: 'ARMED FORCES AMERICA (EXCEPT CANADA)', id: 'AA' },
  { value: 'AP', text: 'ARMED FORCES PACIFIC', id: 'AP' },
].map(createStateDetail);

const stateOptions = StateOptions.reduce(
  (acc, val) => ({
    [val.value.toLowerCase()]: val,
    ...acc,
  }),
  {}
);
export const stateLookup = state => stateOptions[state.toLowerCase()];
