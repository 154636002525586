import './SkippableSection.scss';
import Checkbox from './Checkbox';
import React from 'react';
import classes from 'classnames';
import { noOp } from '../../utilities/funcy';

const wrapperClasses = skipped =>
  classes({
    'skip-blurred': skipped,
  });

const SkippableSection = ({
  label,
  skipped,
  onSkipChanged = noOp,
  children,
  canSkip = true,
}) =>
  canSkip ? (
    <div className="SkippableSection">
      <div className={wrapperClasses(skipped)}>{children}</div>

      <Checkbox label={label} checked={skipped} onChange={onSkipChanged} />
    </div>
  ) : (
    children
  );

export default SkippableSection;
