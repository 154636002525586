import { getFormState, handleFormSubmit } from './formstate';
import { hasItems } from './matchers';

export const FORM_NAME = 'strainInformation';

export const allState = () => ({
  strainState: getFormState(FORM_NAME),
});

const createQueriesCore = strainState => {
  const strainsMap = strainState || {};
  const strains = Object.values(strainsMap);

  return {
    get strainsHaveBeenSetup() {
      return hasItems(strains);
    },

    get strains() {
      return strains;
    },

    get strainDetails() {
      return strainsMap;
    },
  };
};

export const createQueriesHook = ({ strainState } = allState()) =>
  createQueriesCore(strainState.values);

export const createQueriesFromFormState = ({ [FORM_NAME]: strainState }) =>
  createQueriesCore(strainState);

export const createSaveStrainHandler = ({ strainState, completion = noOp }) => strain => {
  handleFormSubmit(strainState)(strain);
  completion();
};
