import {
  companyInformationStep,
  completionStep,
  plantInventoryStep,
  plantsStep,
  resumeStep,
  roomsStep,
  strainsStep,
} from './stepDefinitions';
import { createWorkflow, createWorkflowSelector } from './createWizardWorkflow';
import {
  isRegularWorkflow,
  isWashingtonCriteria,
  onlyRegisteringForRetail,
} from './workflowCriteria';
import { not, createAndMatch as where } from './criteria';

const retailOnlyWorkflow = createWorkflow({
  title: 'Retail Only Workflow',

  availabilityCriteria: onlyRegisteringForRetail,

  steps: [completionStep],
});

const waNonRetailOnlyWorflow = createWorkflow({
  title: 'WA Non Retail Only WorkFlow',

  availabilityCriteria: isWashingtonCriteria.and(not(onlyRegisteringForRetail)),

  steps: [companyInformationStep, completionStep],
});

const normalWorkflow = createWorkflow({
  title: 'Regular Workflow',

  availabilityCriteria: isRegularWorkflow,

  steps: [
    companyInformationStep,
    strainsStep,
    roomsStep,
    plantsStep,
    plantInventoryStep,
    completionStep,
  ],
});

const dataLoadingWorkflow = createWorkflow({
  title: 'Data Loading Workflow',

  availabilityCriteria: where({
    introDataIsAvailable: false,
  }),

  steps: [resumeStep],
});

export const workflowSelector = createWorkflowSelector([
  dataLoadingWorkflow,
  retailOnlyWorkflow,
  waNonRetailOnlyWorflow,
  normalWorkflow,
]);
