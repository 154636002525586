import {
  condition,
  equalTo as equals,
  isEmpty,
  createAndMatch as where,
} from './criteria';
import { getFormState, handleFormSubmit } from './formstate';
import { hasItems, hasNoItems } from './matchers';
import { plantRoomPurposeOptions } from '../data/extraDropDownOptions';

export const INVENTORY_ROOMS_FORM_NAME = 'inventoryRooms';
export const PLANT_ROOMS_FORM_NAME = 'plantRooms';

export const allState = () => ({
  plantRoomsState: getFormState(PLANT_ROOMS_FORM_NAME),
  inventoryRoomsState: getFormState(INVENTORY_ROOMS_FORM_NAME),
});

const createQueriesCore = ({ plantRoomsState, inventoryRoomsState }) => {
  const plantRoomPurposes = () => plantRoomPurposeOptions;

  const inventoryRooms = () => Object.values(inventoryRoomsState || {});

  const plantRooms = () => Object.values(plantRoomsState || {});

  const allRooms = () => plantRooms().concat(inventoryRooms());

  const roomsForLicense = (roomsResolver, license) =>
    roomsResolver().filter(
      where({
        license,
      })
    );

  const allPlantRoomsForLicense = license => roomsForLicense(plantRooms, license);

  const allInventoryRoomsForLicense = license => roomsForLicense(inventoryRooms, license);

  const plantRoomDetails = plantRoomsState;

  const inventoryRoomDetails = inventoryRoomsState;

  const defaultRoomForPurpose = ({ license, purpose }) =>
    allPlantRoomsForLicense(license).find(
      where({
        isDefault: equals(true),
        purpose: equals(purpose),
      })
    );

  const allRoomsForLicense = license =>
    allPlantRoomsForLicense(license).concat(allInventoryRoomsForLicense(license));

  const noRoomsForLicense = license => hasNoItems(allRoomsForLicense(license));

  const hasInventoryRoomsForLicense = condition(license =>
    hasItems(allInventoryRoomsForLicense(license))
  );

  const hasPlantRoomsForLicense = condition(license =>
    hasItems(allPlantRoomsForLicense(license))
  );

  const hasRoomsForLicense = hasInventoryRoomsForLicense.or(hasPlantRoomsForLicense);

  const roomDetails = () =>
    allRooms().reduce(
      (acc, val) => ({
        [val.id]: val,
        ...acc,
      }),
      {}
    );

  const noInventoryRooms = () => isEmpty(inventoryRooms());

  const noPlantRooms = () => isEmpty(plantRooms());

  const noRoomsAreSetup = () => noInventoryRooms() && noPlantRooms();

  return {
    get allRooms() {
      return allRooms();
    },

    get noRoomsAreSetup() {
      return noRoomsAreSetup();
    },

    get plantRooms() {
      return plantRooms();
    },

    plantRoomDetails,

    inventoryRoomDetails,

    get inventoryRooms() {
      return inventoryRooms();
    },

    noRoomsForLicense,

    hasPlantRoomsForLicense,

    hasInventoryRoomsForLicense,

    hasRoomsForLicense,

    allRoomsForLicense,

    allPlantRoomsForLicense,

    allInventoryRoomsForLicense,

    get purposes() {
      return plantRoomPurposes();
    },

    defaultRoomForPurpose,

    get roomDetails() {
      return roomDetails();
    },
  };
};

export const createQueriesHook = (
  { plantRoomsState, inventoryRoomsState } = allState()
) =>
  createQueriesCore({
    plantRoomsState: plantRoomsState.values,
    inventoryRoomsState: inventoryRoomsState.values,
  });

export const createQueriesFromFormState = ({
  [PLANT_ROOMS_FORM_NAME]: plantRoomsState,
  [INVENTORY_ROOMS_FORM_NAME]: inventoryRoomsState,
}) => createQueriesCore({ plantRoomsState, inventoryRoomsState });

export const validatePlantRoom = ({ name, purpose }) => {
  const errors = {};

  if (isEmpty(name)) errors.name = 'Required';
  if (isEmpty(purpose)) errors.purpose = 'Required';

  return errors;
};

export const validateInventoryRoom = ({ name }) => {
  const errors = {};

  if (isEmpty(name)) errors.name = 'Required';

  return errors;
};

export const newPlantRoom = license => ({
  name: '',
  license,
  purpose: '',
  isDefault: false,
});

export const newInventoryRoom = license => ({
  license,
  name: '',
});

const noOp = () => {};

const savePlantRoom = (inventoryRoomsState, plantRoomsState, room) => {
  const queries = createQueriesHook({ inventoryRoomsState, plantRoomsState });
  const existingDefaultRoom = queries.defaultRoomForPurpose(room);
  const submit = handleFormSubmit(plantRoomsState);

  if (existingDefaultRoom) {
    existingDefaultRoom.isDefault = false;
    submit(existingDefaultRoom);
  }

  submit(room);
};

export const createSavePlantRoomHandler = ({
  inventoryRoomsState,
  plantRoomsState,
  completion = noOp,
}) => room => {
  savePlantRoom(inventoryRoomsState, plantRoomsState, room);
  completion();
};

export const createSaveInventoryRoomHandler = ({
  inventoryRoomsState,
  completion = noOp,
}) => room => {
  handleFormSubmit(inventoryRoomsState)(room);
  completion();
};
