import { subtractYearsFromToday, today } from './dates';

import { getFormState } from './formstate';
import { hasItems } from './matchers';

export const FORM_NAME = 'employeeInformation';

const MINIMUM_AGE = 15;

export const MAXIMUM_BIRTH_DATE = subtractYearsFromToday(MINIMUM_AGE);

export const MAXIMUM_HIRE_DATE = today();

export const FIELD_NAMES = [
  'associatedLicenses',
  'associatedVehicles',
  'isDriverOnDeliveries',
  'shouldAddAsUserOnYourGrowFlowAccount',
].reduce((acc, val) => ({ [val]: val, ...acc }), {});

export const PERMISSION_FIELDS = ['isAdministrator', 'hasGrowAccess', 'hasSalesAccess'];

export const DRIVERS_LICENSE_FIELDS = ['state', 'driverLicenseNumber'];

const shouldHaveAtLeastOnePermissionSet = values =>
  values.shouldAddAsUserOnYourGrowFlowAccount &&
  !PERMISSION_FIELDS.some(field => values[field]);

const shouldHaveAtLeastOneVehicleAssigned = ({
  associatedVehicles,
  isDriverOnDeliveries,
  vehicles,
}) => isDriverOnDeliveries && associatedVehicles.length == 0 && vehicles.length > 0;

const shouldHaveAtLeastOneLicenseAssigned = ({ associatedLicenses }) =>
  associatedLicenses.length === 0;

export const validate = ({ values, vehicles }) => {
  const errors = {};

  if (values.firstName === '') errors.firstName = 'Required';
  if (values.lastName === '') errors.lastName = 'Required';
  if (values.email === '') errors.email = 'Required';
  if (values.role === '') errors.role = 'Required';
  if (values.phoneNumber === '') errors.phoneNumber = 'Required';
  if (values.dateOfBirth === '') errors.dateOfBirth = 'Required';
  if (values.dateOfHire === '') errors.dateOfHire = 'Required';

  if (values.isDriverOnDeliveries) {
    if (values.driverLicenseNumber === '') errors.driverLicenseNumber = 'Required';
    if (values.state === '') errors.state = 'Required';
  }

  if (shouldHaveAtLeastOnePermissionSet(values))
    errors.shouldAddAsUserOnYourGrowFlowAccount =
      'At least one permission needs to be assigned';

  if (shouldHaveAtLeastOneVehicleAssigned({ vehicles, ...values }))
    errors.isDriverOnDeliveries = 'At least one vehicle needs to be assigned';

  if (shouldHaveAtLeastOneLicenseAssigned(values))
    errors.shouldHaveAtLeastOneLicenseAssigned = 'At least one license must be selected';

  return errors;
};

export const newEmployee = initialValues => ({
  firstName: (initialValues && initialValues.firstName) || '',
  lastName: (initialValues && initialValues.lastName) || '',
  email: (initialValues && initialValues.email) || '',
  role: (initialValues && initialValues.role) || '',
  phoneNumber: (initialValues && initialValues.phoneNumber) || '',
  driverLicenseNumber: (initialValues && initialValues.driverLicenseNumber) || '',
  state: (initialValues && initialValues.state) || '',
  dateOfBirth: (initialValues && initialValues.dateOfBirth) || '',
  dateOfHire: (initialValues && initialValues.dateOfHire) || '',
  employeeId: (initialValues && initialValues.employeeId) || '',
  isDriverOnDeliveries: (initialValues && initialValues.isDriverOnDeliveries) || false,
  associatedVehicles: (initialValues && initialValues.associatedVehicles) || [],
  associatedLicenses: (initialValues && initialValues.associatedLicenses) || [],
  shouldAddAsUserOnYourGrowFlowAccount:
    (initialValues && initialValues.shouldAddAsUserOnYourGrowFlowAccount) || false,

  isAdministrator: (initialValues && initialValues.isAdministrator) || false,
  hasGrowAccess: (initialValues && initialValues.hasGrowAccess) || false,
  hasSalesAccess: (initialValues && initialValues.hasSalesAccess) || false,
});

export const allState = () => ({
  employeeState: getFormState(FORM_NAME),
});

const createQueriesCore = ({ employeeState, introQueries, licenseQueries }) => {
  const employeeDetails = employeeState || {};
  const employees = Object.values(employeeDetails);

  const employeeWithIntroDefaults = () => ({
    ...newEmployee(),
    role: licenseQueries.jobTitle,
    phoneNumber: introQueries.phone,
    state: introQueries.state,
    firstName: introQueries.firstName,
    lastName: introQueries.lastName,
    email: introQueries.email,
  });

  const employeeWithStateDefaults = () => ({
    ...employeeWithIntroDefaults(),
    phoneNumber: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  return {
    get newEmployee() {
      return employees.length === 0
        ? employeeWithIntroDefaults()
        : employeeWithStateDefaults();
    },

    employeeDetails,
    employees,
    hasEmployees: hasItems(employees),
  };
};

export const createQueriesHook = ({ employeeState, introQueries, licenseQueries }) =>
  createQueriesCore({
    employeeState: employeeState.values,
    introQueries,
    licenseQueries,
  });

export const createQueriesFromFormState = (
  { [FORM_NAME]: employeeState },
  introQueries,
  licenseQueries
) => createQueriesCore({ employeeState, introQueries, licenseQueries });
