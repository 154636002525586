import {
  createQueriesFromFormState as createFormQueriesFromFormState,
  getRawFormState,
} from './formstate';

import { hasItems, hasNoItems } from './matchers';

import { isNotNullOrUndefined, not, createAndMatch as where } from './criteria';

import { createQueriesFromFormState as createEmployeeQueriesFromFormState } from './employees';

import { createQueriesFromFormState as createIntroQueriesFromFormState } from './introInformation';

import { createQueriesFromFormState as createInventoryQueriesFromFormState } from './inventory';

import { createQueriesFromFormState as createLicenseQueriesFromFormState } from './licenses';

import { createQueriesFromFormState as createPlantInventoryQueriesFromFormState } from './plantInventory';

import { createQueriesFromFormState as createPlantQueriesFromFormState } from './plants';

import { createQueriesFromFormState as createPrinterAndLabelQueriesFromFormState } from './printersAndLabels';

import { createQueriesFromFormState as createRoomQueriesFromFormState } from './rooms';

import { createQueriesFromFormState as createSkipQueriesFromFormState } from './skippedEntries';

import { createQueriesFromFormState as createStrainQueriesFromFormState } from './strains';

import { createQueriesFromFormState as createVehicleQueriesFromFormState } from './vehicles';

const licensesWithRooms = ({ licenses, hasRoomsForLicense }) =>
  licenses.filter(hasRoomsForLicense);

const createQueryDataFromFormState = (formState = {}) => {
  const introQueries = createIntroQueriesFromFormState(formState);
  const licenseQueries = createLicenseQueriesFromFormState(formState);
  const formQueries = createFormQueriesFromFormState(formState);

  const employeeQueries = createEmployeeQueriesFromFormState(
    formState,
    introQueries,
    licenseQueries
  );

  const inventoryQueries = createInventoryQueriesFromFormState(formState);

  const plantQueries = createPlantQueriesFromFormState(formState);

  const printerAndLabelQueries = createPrinterAndLabelQueriesFromFormState(formState);

  const roomQueries = createRoomQueriesFromFormState(formState);

  const strainQueries = createStrainQueriesFromFormState(formState);

  const vehicleQueries = createVehicleQueriesFromFormState(formState);

  const plantInventoryQueries = createPlantInventoryQueriesFromFormState(formState);

  const skipQueries = createSkipQueriesFromFormState(formState);

  const core = {
    ...formQueries,
    ...employeeQueries,
    ...introQueries,
    ...inventoryQueries,
    ...licenseQueries,
    ...plantQueries,
    ...printerAndLabelQueries,
    ...roomQueries,
    ...strainQueries,
    ...vehicleQueries,
    ...plantInventoryQueries,
    ...skipQueries,
  };

  return {
    ...core,
    licensesWithRooms: licensesWithRooms(core),
  };
};

const roomsHaveBeenSetupForAllLicenses = ({
  licenses,
  allLicensesAreConfirmed,
  hasInventoryRoomsForLicense,
  skippingInventoryRoomsForLicense,
  hasPlantRoomsForLicense,
  skippingPlantRoomsForLicense,
  skippedSetupOfAllRoomTypesForLicense,
}) =>
  allLicensesAreConfirmed &&
  licenses.every(
    hasPlantRoomsForLicense
      .and(hasInventoryRoomsForLicense)
      .or(hasPlantRoomsForLicense.and(skippingInventoryRoomsForLicense))
      .or(hasInventoryRoomsForLicense.and(skippingPlantRoomsForLicense))
      .or(skippedSetupOfAllRoomTypesForLicense)
  );

const roomsHaveBeenSetupForAtLeastOneLicense = ({
  licenses,
  allLicensesAreConfirmed,
  hasRoomsForLicense,
}) => allLicensesAreConfirmed && licenses.some(hasRoomsForLicense);

const plantRoomsHaveBeenSetupForAtLeastOneLicense = ({
  licenses,
  allLicensesAreConfirmed,
  hasPlantRoomsForLicense,
}) => allLicensesAreConfirmed && licenses.some(hasPlantRoomsForLicense);

const roomsHaveBeenSkippedForAllLicenses = ({
  licenses,
  skippedSetupOfAllRoomTypesForLicense,
}) => licenses.every(skippedSetupOfAllRoomTypesForLicense);

const plantGroupsHaveBeenSkippedForAllRooms = ({
  allLicensesAreConfirmed,
  plantRooms,
  skippingPlantsForRoom,
}) => allLicensesAreConfirmed && plantRooms.every(({ id }) => skippingPlantsForRoom(id));

const inventoryHasBeenSkippedForAllLicensesWithRooms = ({
  allLicensesAreConfirmed,
  licensesWithRooms,
  skippingAllInventoryForLicense,
}) =>
  allLicensesAreConfirmed &&
  hasItems(licensesWithRooms) &&
  licensesWithRooms.every(skippingAllInventoryForLicense);

const plantInventoryHasBeenSetup = ({
  allLicensesAreConfirmed,
  licensesWithRooms,
  hasAnyInventoryForLicense,
}) =>
  allLicensesAreConfirmed &&
  hasItems(licensesWithRooms) &&
  licensesWithRooms.every(hasAnyInventoryForLicense);

const canSkip = ({
  vehiclesAreSetup,
  hasEmployees,
  strainsHaveBeenSetup,
  hasPlantRoomsForLicense,
  hasInventoryRoomsForLicense,
  hasPlantsForRoom,
  hasSeedGroupsForLicense,
  hasCloneGroupsForLicense,
  hasBulkFlowerGroupsForLicense,
  hasShakeTrimGroupsForLicense,
}) => ({
  canSkipVehicles: !vehiclesAreSetup,

  canSkipEmployees: !hasEmployees,

  canSkipStrains: !strainsHaveBeenSetup,

  canSkipPlantRoomsForLicense: not(hasPlantRoomsForLicense),

  canSkipInventoryRoomsForLicense: not(hasInventoryRoomsForLicense),

  canSkipPlantsForRoom: not(hasPlantsForRoom),

  canSkipSeedGroupsForLicense: not(hasSeedGroupsForLicense),

  canSkipCloneGroupsForLicense: not(hasCloneGroupsForLicense),

  canSkipBulkFlowerGroupsForLicense: not(hasBulkFlowerGroupsForLicense),

  canSkipShakeTrimGroupsForLicense: not(hasShakeTrimGroupsForLicense),
});

const canDelete = ({ plants, allPlantGroups, employees }) => ({
  canDeleteRoom: roomId =>
    hasNoItems(plants.filter(where({ roomId }))) &&
    hasNoItems(allPlantGroups.filter(where({ roomId }))),

  canDeleteStrain: strainId =>
    hasNoItems(allPlantGroups.filter(where({ strainId }))) &&
    hasNoItems(plants.filter(where({ strainId }))),

  canDeleteVehicle: vehicleId =>
    hasNoItems(
      employees.filter(
        where({
          associatedVehicles: isNotNullOrUndefined.and(items =>
            items.includes(vehicleId)
          ),
        })
      )
    ),
});

const createQueriesCore = queryData => ({
  ...queryData,

  inventoryHasBeenSkippedForAllLicensesWithRooms: inventoryHasBeenSkippedForAllLicensesWithRooms(
    queryData
  ),

  roomsHaveBeenSetupForAllLicenses: roomsHaveBeenSetupForAllLicenses(queryData),

  roomsHaveBeenSkippedForAllLicenses: roomsHaveBeenSkippedForAllLicenses(queryData),

  roomsHaveBeenSetupForAtLeastOneLicense: roomsHaveBeenSetupForAtLeastOneLicense(
    queryData
  ),

  plantRoomsHaveBeenSetupForAtLeastOneLicense: plantRoomsHaveBeenSetupForAtLeastOneLicense(
    queryData
  ),

  plantGroupsHaveBeenSkippedForAllRooms: plantGroupsHaveBeenSkippedForAllRooms(queryData),

  plantInventoryHasBeenSetup: plantInventoryHasBeenSetup(queryData),

  ...canSkip(queryData),

  ...canDelete(queryData),
});

export const createQueriesFromFormState = formState =>
  createQueriesCore(createQueryDataFromFormState(formState));

export const createQueriesHook = () =>
  createQueriesFromFormState(getRawFormState().formState);
