import { isEmpty as empty, createAndMatch as where } from './criteria';
import { getFormState, handleFormSubmit } from './formstate';
import { hasItems } from './matchers';

export const FORM_NAME = 'plantsInformation';

const NO_STAGE_SELECTED = 0;

export const growingStages = {
  1: {
    id: 1,
    value: 1,
    text: 'Vegging',
    requiresHarvestDate: false,
    requiresWetWeight: false,
  },
  2: {
    id: 2,
    value: 2,
    text: 'Flowering',
    requiresHarvestDate: false,
    requiresWetWeight: false,
  },
  3: {
    id: 3,
    value: 3,
    text: 'Harvesting',
    requiresHarvestDate: true,
    requiresWetWeight: true,
  },
};

export const requiresHarvestDate = stage =>
  stage !== NO_STAGE_SELECTED && growingStages[stage].requiresHarvestDate;

export const requiresWetWeight = stage =>
  stage != NO_STAGE_SELECTED && growingStages[stage].requiresWetWeight;

export const allState = () => ({
  plantsState: getFormState(FORM_NAME),
});

const createQueriesCore = plantsState => {
  const plantsMap = plantsState || {};
  const plants = () => Object.values(plantsMap);

  const stages = () => Object.values(growingStages);

  const plantsForRoom = roomId => plants().filter(where({ roomId }));

  const hasPlantsForRoom = roomId => hasItems(plantsForRoom(roomId));

  return {
    plantsForRoom,
    hasPlantsForRoom,
    get plants() {
      return plants();
    },

    get plantsHaveBeenAdded() {
      return hasItems(plants());
    },

    get plantDetails() {
      return plantsMap;
    },

    get stages() {
      return stages();
    },
  };
};

export const createQueriesHook = ({ plantsState } = allState()) =>
  createQueriesCore(plantsState.values);

export const createQueriesFromFormState = ({ [FORM_NAME]: plantsState }) =>
  createQueriesCore(plantsState);

export const validatePlant = form => {
  if (!requiresHarvestDate(form.stage)) form.harvestDate = '';
  if (!requiresWetWeight(form.stage)) form.wetWeight = '';

  let result = ['dateOfBirth', 'strainId', 'plantCount'].reduce(
    (acc, key) => (!empty(form[key]) ? acc : { [key]: 'Required', ...acc }),
    {}
  );

  if (form.stage === NO_STAGE_SELECTED) result.stage = 'Required';

  if (requiresHarvestDate(form.stage) && empty(form.harvestDate))
    result.harvestDate = 'Required';

  if (requiresWetWeight(form.stage) && empty(form.wetWeight))
    result.wetWeight = 'Required';

  return result;
};

export const newPlant = roomId => ({
  roomId,
  strainId: '',
  dateOfBirth: '',
  harvestDate: '',
  plantCount: '',
  stage: NO_STAGE_SELECTED,
  wetWeight: '',
});

const noOp = () => {};

const savePlant = (plantState, plant) => {
  const submit = handleFormSubmit(plantState);

  submit(plant);
};

export const createSavePlantHandler = ({ plantsState, completion = noOp }) => plant => {
  savePlant(plantsState, plant);
  completion();
};
